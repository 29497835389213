@media only screen and (min-width: 1600px) {
  .create-bot {
    padding: unset;
    margin: unset;
  }
}

@media only screen and (max-width: 768px) {
  #botlist .filter {
    flex-direction: column;
  }

  ul#topmenush li {
    padding: 5px 10px;
    font-size: 12px;
    text-align: center;
  }

  .connectagain,
  .deletekey {
    font-size: 10px;
    border-radius: 20px;
    white-space: nowrap;
    padding: 5px 10px;
  }

  .connectinfo {
    right: 0px;
    top: 2px;
  }

  .scrolltable td:nth-child(1) {
    background-color: white;
  }

  .theme-dark .scrolltable td:nth-child(1) {
    background-color: #000000;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .createbotbox {
    width: 50%;
  }
}
